import * as React from "react"
import logo from "../../static/logo.png"

const HeaderTitle = (data) => (
    <header className="w-full to-white from-blue-100 bg-gradient-to-b">
        <div className="p-10 mx-auto max-w-md">
            <img src={logo} alt="site logo" height="95.491" width="368" />        
            <h2 className="text-center text-lg">{data.message}</h2>            
        </div>        
    </header>    
  )  

  export default HeaderTitle 