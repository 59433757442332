import * as React from "react"
import BlogSummary from "./content/blog-summary.component"
import BookSummary from "./content/book-summary.component"

const ListSummary = (data) => {         
    const types = [ BookSummary, BlogSummary ]    
    return (
    <div className="flex flex-col space-y-5 pt-5">
        {data.articles.map(article => {             
            const slug = article.frontmatter.slug;
            const type = slug.split("/").filter(s=>s.length>0)[0];
            const component = types.filter(t=>t.type === type)
            if (component.length > 0) {
                return React.createElement(component[0], { key: slug, article: article, bloggers: data.bloggers});
            }
            return (<div />)
        })}
    </div>)
}

export default ListSummary