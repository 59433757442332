import * as React from "react"
import { Link } from "gatsby"
import gravatar from "../../gravatar"

const BookSummary = ({article, bloggers}) =>{
    const {slug, blogger,tags,date,link,image, title} = article.frontmatter;
    return (
    <div id={slug} className="py-5 grid grid-cols-7 grid-rows-3 grid-flow-dense">            
        <h2 className="text-2xl row-span-1 col-span-6">
            <Link to={slug}>{title}</Link>
        </h2>                    
        <section className="text-md col-span-6">
            <p>{article.excerpt} <Link to={slug} className="text-gray-500">»</Link></p>
        </section>  
        <div className="col-span-1 row-span-3">
            <a href={link} target="_blank" rel="noreferrer">
                <img className="w-full object-cover object-top" src={image} alt={`Book cover of ${title}`}/>
            </a>
        </div>        
        <footer className="col-span-6 flex flex-row gap-2">            
            <img className="w-10 p-1 h-10 rounded-full" src={"//www.gravatar.com/avatar/" + gravatar(bloggers, blogger) + "?d=404&amp;s=250"} alt="Author" nopin="nopin"/>
            <Link className="py-2" to={"/cobbler/" + blogger}>{bloggers[blogger].name}</Link>
            {tags.length > 0 && 
            ( <span className="py-2">
            <span className="text-gray-500"> on </span> {tags.map(tag => (
                <Link to={"/tags/" + tag.replace("@", "") +"/"} key={tag} style={{paddingRight:"3px"}}>{tag}</Link>
            ))}
            </span>)
            }
            <span className="py-2">|</span>
            <time className="py-2" dateTime={date}>{date}</time>
        </footer>                                                      
    </div>)
}
BookSummary.type="book";
export default BookSummary;
