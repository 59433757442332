import * as React from "react"
import { graphql } from "gatsby"
import HeaderTitle from "../templates/header-title.component"
import Footer from "../templates/footer.component"
import { Helmet } from "react-helmet"
import ListSummary from "../templates/list-summary.component"
import PageSidebar from "../templates/page-sidebar.component"
import LinksContainer from "../templates/links-container.component"
import Navigation from "../templates/navigation.component"
// markup
const IndexPage = ({
  data,
}) => {
  const articles = data.allMdx.nodes;
  const tags = data.tags.group.filter(n => n.totalCount > 1);
  const bloggers = data.bloggers.edges.reduce((model, blogger) => {
    model[blogger.node.basics.id] = {
      "name": blogger.node.basics.name,
      "email": blogger.node.basics.email
    }
    return model;
  }, {});

  let navLinks  = []
  navLinks.push({name: "Back to Top", href: "#top", className:"text-sm text-right" });
  navLinks.push({name: "Popular Tags", className:"text-xl" });
  for (var tag of tags) {
      navLinks.push({name: `${tag.tag} (${tag.totalCount})`, href: "/tags/" + tag.tag.replace("@", "") + "/" });
  }
  
  return (
    <main className="">
      <Helmet>
        <title>bitCobblers </title>
        <html lang="en" />
      </Helmet>
      <HeaderTitle message="Digital craftsmanship with a personal touch."></HeaderTitle>
      <Navigation></Navigation>
      <PageSidebar sidebar={<LinksContainer links={navLinks} />}>
          <ListSummary articles={articles} bloggers={bloggers}></ListSummary>          
      </PageSidebar>                     
      {/* <Footer></Footer> */}
    </main>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: {order: DESC, fields: frontmatter___date}
      filter: {frontmatter: {published: {eq: true}}}
    ) {
      nodes {
        frontmatter {
          slug
          title
          blogger
          date
          tags
          image
          link
        }
        excerpt(pruneLength: 480)
      }
    }
    
    tags: allMdx 
      (filter: {frontmatter: {published: {eq: true}}}){
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }      
    }

    bloggers: allResumeJson {
      edges {
        node {
          basics {
            id
            email
            name
          }
        }
      }
    }
  
  }
`

export default IndexPage